<template>
  <div class="Email">
    <ValidationObserver ref="observer" v-slot="{ invalid, passes }">
      <form ref="form"
            @submit.prevent="passes(onFormSubmit)">
        <div v-if="general">
          <div class="description" v-if="!channel">
            In order to get notifications for your monitors we need your <a href="https://account.signl4.com/manage/Apps/Webhook">SIGNL4 webhook</a>.
          </div>
          <base-alert type="error" v-if="webHookError" class="mt-3">
            Something went wrong
          </base-alert>

          <div class="webhook" v-for="(channel, index) in userGeneralNotificationChannels" :key="index">
            <div class="remove"
                 @click.prevent="deleteGeneralChannel(channel)">
              <icon-trash width="18"
                          height="18" />
            </div>
            <div class="text">{{ channel.item_value }}</div>
          </div>

          <template>
            <base-textbox ref="webhook"
                          placeholder="https://connect.signl4.com/webhook/{yourTeamKey}"
                          rules="url"
                          v-model="webhook" />
            <base-button class="button-save"
                         color="primary"
                         :loading="submitting"
                         :disabled="!webhook || invalid"
                         @click.prevent="onFormSubmit">Save</base-button>
          </template>
        </div>
        <div v-else>
          <div class="description" v-if="!channel">
            In order to get notifications for
            <monitor-label :monitor="monitor"
                           :show-favicon="false" />
            we need your <a href="https://account.signl4.com/manage/Apps/Webhook">SIGNL4 webhook</a>.
          </div>

          <div class="description" v-if="channel">
            You have enabled SIGNL4 notifications for <monitor-label :monitor="monitor"
                                                                     :show-favicon="false" />
          </div>

          <base-alert type="error" v-if="webHookError" class="mt-3">
            Something went wrong
          </base-alert>

          <div class="webhook" v-if="channel">
            <div class="remove"
                 @click.prevent="deleteChannel(channel)">
              <icon-trash width="18"
                          height="18" />
            </div>
            <div class="text">{{ channel.signl4_webhook }}</div>
          </div>

          <template v-if="!channel">
            <base-textbox ref="webhook"
                          placeholder="https://connect.signl4.com/webhook/{yourTeamKey}"
                          rules="url"
                          v-model="webhook" />
            <base-button class="button-save"
                         color="primary"
                         :loading="submitting"
                         :disabled="!webhook || invalid"
                         @click.prevent="onFormSubmit">Save</base-button>
          </template>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import MonitorLabel from '@/components/Monitor/MonitorLabel.vue'

import notificationChannelsApi from '@/api/monitorNotifications/notificationChannelsApi.js'
import signl4Api from '@/api/monitorNotifications/signl4Api.js'
import usersApi from '../../../api/usersApi'

export default {
  components: {
    MonitorLabel
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      webhook: '',
      channel: null,
      submitting: false,
      webHookError: false,
      userGeneralNotificationChannels: null
    }
  },

  created () {
    if (this.general) {
      this.loadGeneralNotificationChannels()
    }
  },

  mounted () {
    this.flushNewHookFocus()
  },

  methods: {
    async onFormSubmit () {
      if (this.general) {
        this.webHookError = false
        this.submitting = true
        try {
          await signl4Api.generalStore(this.webhook)
          await this.loadGeneralNotificationChannels()
        } catch (e) {
          this.webHookError = true
        }
        this.submitting = false
      } else {
        this.webHookError = false
        this.submitting = true

        try {
          await signl4Api.store(this.monitor.id, this.webhook)
          this.$emit('update')
        } catch (e) {
          this.webHookError = true
        }
        this.submitting = false

        this.flushNewHookFocus()
      }
    },

    async deleteChannel (channel) {
      this.webHookError = false
      try {
        await notificationChannelsApi.delete(channel.id)
        this.$emit('update')
      } catch (e) {
        this.webHookError = true
      }
    },

    async deleteGeneralChannel (channel) {
      await notificationChannelsApi.generalDelete(channel.id)
      await this.loadGeneralNotificationChannels()
    },

    async loadGeneralNotificationChannels () {
      try {
        this.userGeneralNotificationChannels = await usersApi.getGeneralChannels('signl4')
      } catch (e) {
        this.generalError = true
      }
    },

    flushNewHookFocus () {
      if (this.$refs.webhook) {
        this.$refs.webhook.focus()
      }
    }
  },

  watch: {
    monitor: {
      handler: function () {
        if (!this.general) {
          const channels = this.monitor.notification_channels
            .filter(channel => channel.via === 'signl4')

          this.channel = channels.length > 0 ? channels[0] : null
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .Email {
    max-width: 550px;

    .description {
      margin-bottom: 10px;
      color: #777;
      line-height: 1.5;
    }

    .webhook {
      display: flex;
      align-items: center;

      .remove {
        cursor: pointer;
        padding: 10px 10px 10px 0;

        &:hover ::v-deep svg g {
          transition: 0.2s all ease;
          fill: map-get($colors, purple-1) !important;
        }
      }

      .text {
        margin-bottom: 3px;
      }
    }

    .button-save {
      width: 100px;
    }
  }
</style>
